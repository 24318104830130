<template>
  <div class="hydropower-container" style="width: 385px">
    <CSSelect height="40px" iWidth="36px" style="width: 385px;border:none">
      <input
        type="text"
        class="readonly-input"
        placeholder="请选择"
        maxlength="20"
        v-model="selectInput"
        @click="readonlyBtn"
      />
    </CSSelect>
    <div class="location-panel" v-show="isDisplay" @click="isDisplay = true">
      <div class="location-search">
        <input
          type="text"
          :placeholder="placeholderName"
          class="location-input"
          v-model="queryInput"
        />
        <button class="btn btn-primary font" @click="getAllList">查询</button>
      </div>
      <!-- 下拉内容区租客 -->
      <div class="location-result" v-if="selectName == 'room'">
        <p
          class="p"
          v-for="item in TenantAllList"
          :key="item.id"
          @click.stop="selectWarte($event,item)"
        >
          {{ `${item.companyName}-${item.dutyName}(${item.phone})` }}
        </p>
      </div>
      <!-- 下拉区水表 -->
      <div class="location-result" v-if="selectName == 'warte'">
        <p
          class="p"
          v-for="building in wartListArr"
          :key="building.id"
          @click.stop="selectWarte($event,building)"
        >
          {{ `${building.meterNo}-${building.correlationRoom}` }}
        </p>
      </div>
      <div class="location-result" v-else>
          <p
              class="p"
              v-for="building in wartListArr"
              :key="building.id"
              @click.stop="selectWarte($event,building)"
          >
              {{ `${building.meterNo}-${building.correlationRoom}` }}
          </p>
      </div>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import { HYDROPOWER } from "@/constant";
import { hydropowerGetTenantUrl, hydropowerRoomIdUrl } from "@/requestUrl";
export default {
  props: ["selectName"],
  data() {
    return {
      TenantAllList: [], //所有租客列表
      selectInput: "", //选择框内容
      queryInput: "", //查询框内容
      isDisplay: false, //是否显示下拉框
      placeholderName: "",
      wartListArr: [],
      tenant: {}, //房间信息
    };
  },
  created() {
    this.$vc.on("recharge", "getTenantAllList", (res) => {
      this.getAllList();
    });

    this.$vc.on("rechargeOffline", (res) => {});

    document.getElementsByTagName("body")[0].addEventListener("click", () => {
      this.isDisplay = false;
    });
  },
  mounted() {
    document
      .getElementsByClassName("room-form")[0]
      .addEventListener("click", () => {
          
        this.isDisplay = false;
      },true);
    this.$vc.on("rechargeOffline", "editRechargeTenant", (res) => {
      this.tenant = res;
    });
    switch (this.selectName) {
      case "room": {
        this.placeholderName = "搜索租客/对接人";
        break;
      }
      case "warte": {
        this.placeholderName = "搜索水表编号/关联房间";
        break;
      }
      case "electricity": {
        this.placeholderName = "搜索电表编号/关联房间";
        break;
      }
    }
  },
  components: {
    CSSelect,
  },
  methods: {
    //控制下拉框是否显示
    readonlyBtn() {
      this.isDisplay = true//!this.isDisplay;
      this.getAllList();
    },
    //选择水表
    selectWarte(e,item) {
      switch (this.selectName) {
        case "room": {
          this.$vc.emit("preStorage", "selectWarte", item);
          this.selectInput = `${item.companyName}-${item.dutyName}(${item.phone})`;
          this.isDisplay = false;
          break;
        }
        case "warte": {
          this.$vc.emit("preStorage", "selectRoom", item);
          this.selectInput = `${item.meterNo}-${item.correlationRoom}`;
          this.isDisplay = false;
          break;
        }
        case "electricity": {
          this.$vc.emit("preStorage", "selectRoom", item);
          this.selectInput = `${item.meterNo}-${item.correlationRoom}`;
          this.isDisplay = false;
          break;
        }
      }
    },
    //获取所有列表信息
    getAllList() {
      switch (this.selectName) {
        case "room": {
          this.getTenantAllList();
          break;
        }
        case "warte": {
          this.changeBuilding();
          break;
        }
        case "electricity": {
            this.changeBuilding();
            break;
        }
      }
    },
    //获取所有租客列表
    getTenantAllList() {
      this.$fly
        .post(hydropowerGetTenantUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          search: this.queryInput,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.TenantAllList = res.data;
        });
    },
    //根据租客信息获取水表列表
    changeBuilding() {
      if (!this.tenant.id) {
        return;
      }
      if (this.selectName == "warte") {
        this.type = HYDROPOWER.WATER_METER;
      } else {
        this.type = HYDROPOWER.WATT_HOUR_METER;
      }
      this.$fly
        .post(hydropowerRoomIdUrl, {
          regionCode: this.$vc.getCurrentRegion().code, //区域编码
          type: this.type, //类型 1水表 2电表
          lesseeId: this.tenant.id, //租客id
          search: this.queryInput,
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.wartListArr = res.data;
          this.$vc.emit("hydropowerSelect", "changeBuilding", res.data);
        });
    },
  },
};
</script>

<style scoped lang="stylus">
.hydropower-container {
}

.readonly-input {
  border-radius: 4px;
  border: 1px solid #999;
  padding: 0 10px;
  border: unset;
  font-size: 24px;
  outline: none;
  width: 100%;
}

.location-result {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px 0;
}

.location-panel {
  position: absolute;
  background: #fff;
  z-index: 9;
  top: 50px;
  left: 0;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  width: var(--location-panel-width, 400px);
  padding: 10px;
}

.font {
  font-size: 20px;
  vertical-align: middle;
  float: right;
  height: 40px;
  padding: 0;
  width: 60px;
  line-height: 40px;
  box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
}

.location-search {
  width: 100%;
}

.location-search .location-input {
  border-radius: 4px;
  border: 1px solid #999;
  padding: 0 10px;
  vertical-align: middle;
  width: 300px;
  height: 40px;
  box-sizing: border-box;
}

.p {
  border-bottom: 1px solid #f0f0f0;
}
</style>