<template>
  <div class="recharge-container">
    <CSDialog
      dialogTitle="线下充值"
      dialogWidth="930px"
      :dialogVisible="addRoomVisible"
      @onClose="addRoomVisible = false"
      @onConfirm="edit" :is-submitting="isSubmitting"
      dialog-confirm-btn-text="确认"
      ><div slot="dialog-content" class="room-form">
        <div class="field-item">
          <label class="field-item-label">选择租客</label>
          <div class="field-item-content" style="position: relative">
            <CSSelect height="40px" iWidth="36px" style="width: 385px">
              <!-- <select id="typelist" v-model="editRecharge.tenant" @change="changeBuilding" >
                <option value="">请选择</option>
                <option
                  :value="item"
                  v-for="item in TenantAllList"
                  :key="item.id"
                >
                  {{ `${item.companyName}-${item.dutyName}(${item.phone})` }}
                </option>
              </select> -->
              <HydropowerSelect selectName="room"></HydropowerSelect>
            </CSSelect>
          </div>
        </div>
        <div class="field-item">
          <label class="field-item-label"> 选择{{ nameSurface }} </label>
          <div class="field-item-content" style="position: relative">
            <CSSelect height="40px" iWidth="36px" style="width: 385px">
              <!-- <select
                v-model="editRecharge.hydropowerId"
                @change="changeWart"
                @click="changeWartBtn"
              >
                <option value="">请选择</option>
                <option
                  v-for="building in wartListArr"
                  :key="building.id"
                  :value="building"
                >
                  {{ `${building.meterNo}-${building.correlationRoom}` }}
                </option>
              </select> -->
              <HydropowerSelect
                :selectName="nameSurface == '水表' ? 'warte' : 'electricity'"
                :tenant="this.editRecharge"
                @click.native="changeWartBtn"
              ></HydropowerSelect>
            </CSSelect>
          </div>
        </div>
        <div class="field-item">
          <label class="field-item-label">充值金额 (元)</label>
          <div class="field-item-content">
            <input
              style="width: 385px"
              v-model="editRecharge.money"
              type="Number"
              placeholder="限整数"
              maxlength="10"
            />
          </div>
        </div>
        <!-- 选择支付方式 -->
        <div class="field-item">
          <label class="field-item-label" style="margin-top: -10px"
            >支付渠道</label
          >
          <div
            class="content-checkbox field-item-content"
            @click="selectPayment(index)"
            v-for="(item, index) in selectPaymentList"
            :key="index"
          >
            <img
              :src="editRecharge.target == index ? icon3 : icon5"
              class="imgSele"
            />
            <span
              class="spanSele"
              style="font-size: 24px; vertical-align: middle"
              >{{ item }}</span
            >
          </div>
        </div>
        <!-- //付款凭证上传 -->
        <div class="field-item">
          <label class="field-item-label">付款凭证</label>
          <div style="display: inline-block; vertical-align: top">
            <template v-for="(src, index) in editRecharge.photos">
              <div class="commodity-img" :key="index">
                <img
                  :src="src"
                  style="width: 100px; height: 100px; margin-right: 20px"
                />
                <img
                  @click="deleteFacePhoto(index)"
                  src="../assets/icon4.png"
                  style="
                    width: 15px;
                    height: 15px;
                    position: absolute;
                    top: -5px;
                    right: -5px;
                  "
                />
              </div>
            </template>
            <template v-if="editRecharge.photos.length < 2">
              <div
                class="upload-photo"
                style="width: 100px; height: 100px"
                @click="emitChoosePhoto"
              >
                <div class="text-center">
                  <img
                    src="@/assets/upload.png"
                    alt=""
                    style="margin: 5px auto 5px"
                  />
                  <p style="color: #999">上传照片</p>
                </div>
              </div>
              <input
                type="file"
                class="file"
                accept="images/*"
                id="uploadOwnerPhoto"
                hidden
                @change="showImage($event)"
              />
            </template>
          </div>
        </div>
      </div>
    </CSDialog>
  </div>
</template>
<script>
import CSDialog from "@/components/common/CSDialog";
import CSSelect from "@/components/common/CSSelect";
import * as icon5 from "@/assets/iconK.svg";
import * as icon3 from "@/assets/iconS.svg";
import { PAY_CHANNEL_MAPPING, PAY_CHANNEL, HYDROPOWER } from "@/constant";
import HydropowerSelect from "@/components/hydropowerSelect.vue";
import {
  hydropowerDrawABillUrl,
  hydropowerGetTenantUrl,
  hydropowerRoomIdUrl,
} from "@/requestUrl";
export default {
  props: ["nameSurface"],
  data() {
    return {
        isSubmitting: false,
        type: "", //水电表类型
      wartListArr: [], //水表列表
      TenantAllList: [], //租户列表
      icon5,
      icon3,
      editRecharge: {
        tenant: "", //租户信息
        hydropowerId: "", //表ID
        money: "", //充值金额
        target: 0, //支付方式 3现金 2银行卡 1 支付宝 0微信
        faceUrl: "", //图片上传地址
        photos: [],
        cid: "",
      },
      //   控制充值面板的显隐
      addRoomVisible: false,
      //支付方式
      selectPaymentList: {
        0: "现金支付",

        1: "银行转账",

        2: "支付宝支付",

        3: "微信转账",
      },
    };
  },
  mounted() {
    this.$vc.on("preStorage", "selectRoom", (res) => {
      //this.wartListArr = res;
      this.editRecharge.hydropowerId = res;
    });
    this.$vc.on("hydropowerSelect", "changeBuilding", (res) => {
      this.wartListArr = res;
    });
    this.$vc.on("preStorage", "selectWarte", (res) => {
      this.getRomList(res);
    });
    this.$vc.on("recharge", "getTenantAllList", (res) => {
      this.getTenantAllList();
    });
    this.$vc.on("recharge", "openRecharge", () => {
      //   this.refreshEditRoomInfo();
      //   this.getBuildings();
      this.editRecharge.tenant = "";
      this.addRoomVisible = true;
    });
  },
  components: {
    CSDialog,
    CSSelect,
    HydropowerSelect,
  },
  methods: {
    //获取选择的房间
    getRomList(item) {
      this.editRecharge.tenant = item;
      this.$vc.emit(
        "rechargeOffline",
        "editRechargeTenant",
        this.editRecharge.tenant
      );
      //this.changeBuilding();
    },
    //触发上传图片
    emitChoosePhoto() {
      $("#uploadOwnerPhoto").trigger("click");
    },
    //选择水表
    changeWart() {
      this.editRecharge.cid = this.editRecharge.hydropowerId.cid;
    },
    //随表列点击事件
    changeWartBtn() {
      console.log(this.editRecharge.tenant?.id);
      if (!this.editRecharge.tenant.id) {
        this.$vc.toast("请先选择租客");
      }
    },
    //获取所有租客列表
    getTenantAllList() {
      this.$fly
        .post(hydropowerGetTenantUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          search: "",
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.TenantAllList = res.data;
        });
    },
    //下拉框变化
    // changeBuilding() {
    //   if (!this.editRecharge.tenant) {
    //     return;
    //   }
    //   if (this.nameSurface == "水表") {
    //     this.type = HYDROPOWER.WATER_METER;
    //   } else {
    //     this.type = HYDROPOWER.WATT_HOUR_METER;
    //   }
    //   this.$fly
    //     .post(hydropowerRoomIdUrl, {
    //       // regionCode: this.$vc.getCurrentRegion().code, //区域code
    //       regionCode: this.$vc.getCurrentRegion().code, //区域编码
    //       type: this.type, //类型 1水表 2电表
    //       lesseeId: this.editRecharge.tenant.id, //租客id
    //       search: "",
    //     })
    //     .then((res) => {
    //       if (res.code != 0) {
    //         return;
    //       }
    //       this.wartListArr = res.data;
    //     });
    // },
    //保存
    edit() {
      if(!this.editRecharge.money){
        this.$vc.toast("请填写充值金额");
        return
      }
      if (this.nameSurface == "电表") {
        this.type = HYDROPOWER.WATER_METER;
      } else {
        this.type = HYDROPOWER.WATT_HOUR_METER;
      }
        this.isSubmitting = true;
      this.$fly
        .post(hydropowerDrawABillUrl, {
          lesseeId: this.editRecharge.tenant.id, //租客id
          companyName: this.editRecharge.tenant.companyName, //租客名称
          meterId: this.editRecharge.hydropowerId.meterId, //表id
          type: this.editRecharge.hydropowerId.type, //表类型
          amount: this.editRecharge.money, //充值金额
          payChannel: this.editRecharge.target, //支付渠道   支付渠道 0:现金 1:银行 2:支付宝 3:微信
          roomId: this.editRecharge.hydropowerId.roomId, //房间id
          correlationRoom: this.editRecharge.hydropowerId.correlationRoom, //"家具家电", //关联房间
          cid: this.editRecharge.hydropowerId.cid,
          payOrder: this.editRecharge.photos, //["http://xxx", "支付凭证json串"], //支付凭证图片数组
        })
        .then((res) => {
          if (res.code != 0) {
            return;
          }
          this.$vc.toast("充值成功");
          this.addRoomVisible = false;
          this.type = ""; //水电表类型
          this.wartListArr = []; //水表列表
          this.TenantAllList = []; //租户列表
          this.editRecharge = {
            tenant: "", //租户信息
            hydropowerId: "", //表ID
            money: "", //充值金额
            target: 0, //支付方式 3现金 2银行卡 1 支付宝 0微信
            faceUrl: "", //图片上传地址
            photos: [],
          };
          this.$vc.emit("recharge", "queryNew", {});
        }).finally(() => this.isSubmitting = false);
    },
    //选择支付方式
    selectPayment(item) {
      this.editRecharge.target = item;
    },
    //点击上传图片
    uploadSearchFace() {
      $("#searchFaceBtn").trigger("click");
    },

    //上传图片
    async showImage(event) {
      var photoFiles = event.target.files;
      if (photoFiles && photoFiles.length > 0) {
        // 获取目前上传的文件
        var file = photoFiles[0]; // 文件大小校验的动作
        if (file.size > 1024 * 1024 * 2) {
          this.$vc.toast("图片大小不能超过 2M!");
          return false;
        }

        let formData = new FormData(); //新建FormData对象
        formData.append("files", photoFiles[0]);
        formData.append("bucketName", this.Constants.BUCKET_NAMES.IMG.name);
        formData.append("compress", "true");
        const photo = await this.Constants.uploadFileOne(formData);
        if (photo) {
          this.editRecharge.photos.push(this.Constants.BUCKET_NAMES.IMG.visitUrl + photo);
        }


        // var reader = new FileReader(); //新建FileReader对象
        // reader.readAsDataURL(file); //读取为base64
        // reader.onloadend = async (e) => {
        //   let imgSrc = await this.uploadPhoto(reader.result).catch((err) => {
        //     this.$vc.toast("上传图片错误,请重新上传");
        //   });
        //   if (imgSrc && imgSrc != "") {
        //     this.editRecharge.photos.push(imgSrc);
        //   }
        // };
        // event.target.value = "";
      }
    },
    async uploadPhoto(base64) {
      return new Promise((reslove, reject) => {
        this.$vc.http.post(
          "uploadImg",
          "upload",
          {
            img: base64,
          },
          {
            headres: {
              "Content-Type": "application/json",
            },
          },
          (resStr, res) => {
            reslove(res.body.fileSaveName);
          },
          (errText, err) => {
            reject(errText);
          }
        );
      });
    },
    //删除图片
    deleteFacePhoto(index) {
      this.editRecharge.photos.splice(index, 1);
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../public/common/static/css/component/mallManage.styl';

.room-form {
  padding: 27px 30px;

  .field-item {
    margin-bottom: 22px;
    font-size: 0;

    &-label {
      font-size: 24px;
      text-align: right;
      width: 208px;
      margin-right: 40px;
      display: inline-block;
      vertical-align: middle;
      line-height: 1;
      margin-bottom: 0;
    }

    &-content {
      display: inline-block;
      vertical-align: middle;
      font-size: 24px;

      input {
        width: 400px;
        height: 40px;
        text-indent: 5px;
        border-radius: 4px;
        border: 1px solid #979797;

        &::placeholder {
          color: #999;
        }
      }

      select {
        width: 220px;
        text-indent: 5px;
      }
    }
  }

  .tips {
    color: #999;
    font-size: 20px;
  }
}

.content-checkbox {
  display: inline-block;
  margin: 0 20px 14px 0;
}

.imgSele {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
</style>
